import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const ComparisonOfInstantWinAndLaterLottery = ({ location }) => {
  const title =
    "インスタントウィンキャンペーン（即時抽選）と手動キャンペーン（後日抽選）どちらがおすすめ？インスタントウィンの仕組みとメリット・デメリットを解説します"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="インスタントウィンキャンペーンって何？効果は？仕組みは？メリットは？"
          pagedesc="「Twitterキャンペーンでツールを導入してインスタントウィンキャンペーンを実施するメリットは？」と疑問を持っていますか？インスタントウィンキャンペーンの仕組みとメリットについてご紹介します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/comparison-of-instant-win-and-later-lottery.jpg"
          pagepath="/blog/comparison-of-instant-win-and-later-lottery"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は7分で読むことができます
            </div>
            <h1>
              インスタントウィンキャンペーン（即時抽選）と手動キャンペーン（後日抽選）どちらがおすすめ？インスタントウィンの仕組みとメリット・デメリットを解説します
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年11月13日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="くじ引きの紙"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/comparison-of-instant-win-and-later-lottery.jpg"
                className="w-full"
              />
              <h2>
                Twitterキャンペーンはインスタントウィン（即時抽選）と手動（後日抽選）のどちらがいいの？
              </h2>
              <p>
                このページを見ているあなたは、Twitterキャンペーンの実施を検討しているのではないでしょうか？さらに言うと、
                <strong>
                  「Twitterキャンペーンって手動（後日抽選）で十分では？ツールを導入してインスタントウィン（即時抽選）するメリットってあるの？」
                </strong>
                と疑問を持っているのではないでしょうか？Twitterキャンペーンは手動で実施できるため、ツールを導入する必要性について疑問に感じるのは当然です。
              </p>
              <h2>
                インスタントウィンキャンペーンは、効果が倍増＆工数の削減ができる！
              </h2>
              <p>
                しかし、予算があるのであれば、
                <strong>
                  ツールを導入しインスタントウィンキャンペーンを実施することをおすすめ
                </strong>
                します。
                <strong>
                  ツールを導入することで、認知度の向上やフォロワー数の増加が期待できるだけでなく、工数が削減できるというメリットもあるからです。
                </strong>
              </p>
              <p>
                この記事では、インスタントウィンキャンペーン（即時抽選）の仕組みのご紹介や、手動のキャンペーンとの比較、メリットとデメリットについて解説します。
              </p>
              <p>この記事は次のような人にオススメです！</p>
              <ul className="blog__border-list">
                <li>Twitterキャンペーンをこれから開催しようと考えている方</li>
                <li>
                  自動と手動のどちらのキャンペーン実施が良いのかわからない方
                </li>
                <li>
                  ツールを導入してキャンペーンを実施するメリットを知りたい方
                </li>
              </ul>
            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    インスタントウィンキャンペーンとは
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    インスタントウィンキャンペーンの仕組みは？どうやって実現しているの？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    インスタントウィンキャンペーンと手動のキャンペーンの違いは？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    インスタントウィンキャンペーンのメリット・デメリット
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    メリット
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        工数・人件費の削減になる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        当選者選出の手間と時間がかからない
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        手軽なので何度も実施できる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        短期間で多くのフォロワー獲得が期待できる
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id10" className="toc__link">
                    デメリット
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        費用がかかる
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id12" className="toc__link">
                    インスタントウインキャンペーンをやるべき？それとも手動でやるべき？
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">インスタントウィンキャンペーンとは</h2>
              <p>
                インスタントウィンとは、参加するとすぐに抽選結果が分かる懸賞のことを言います。このインスタントウィンの仕組みを使い、Twitterで行う懸賞のことを「インスタントウィンキャンペーン」と呼びます。
              </p>
              <p>
                Twitterのインスタントウィンキャンペーンでは、「フォロー＆リツイートで〇〇がもらえる！」といったフォロー＆リツイートで景品が当たるキャンペーンが一般的です。また、フォロー＆リツイートして参加条件を満たすことで、リプライやDMが届き、すぐに抽選結果がわかるのも大きな特徴の一つです。
              </p>
              <p>
                下記のツイートは、弊社の公式アカウントで実施したインスタントウィンキャンペーンです。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <br />
                  PARKLoT🎃
                  <a href="https://twitter.com/hashtag/%E3%83%8F%E3%83%AD%E3%82%A6%E3%82%A3%E3%83%B3%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ハロウィンキャンペーン
                  </a>
                  <br />
                  第3弾！
                  <br />＼
                  <a href="https://twitter.com/hashtag/%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BCRT?src=hash&amp;ref_src=twsrc%5Etfw">
                    #フォローRT
                  </a>
                  でその場で当たる！
                  <a href="https://twitter.com/hashtag/Amazon%E3%82%AE%E3%83%95%E3%83%88%E5%88%B8?src=hash&amp;ref_src=twsrc%5Etfw">
                    #Amazonギフト券
                  </a>
                  10,000円分👀をプレゼント💫
                  <br />
                  <br />
                  今すぐリツイートしてね！
                  <br />
                  <br />
                  【応募方法】
                  <br />
                  1️⃣
                  <a href="https://twitter.com/PARKLoT1?ref_src=twsrc%5Etfw">
                    @PARKLoT1
                  </a>
                  をフォロー
                  <br />
                  2️⃣この投稿をRT🔁
                  <br />
                  📩DMで結果確認
                  <br />
                  〆切 10/31
                  <br />
                  <br />
                  👇詳細/通知が来ない
                  <a href="https://t.co/zQKvgaTi8U">https://t.co/zQKvgaTi8U</a>
                  <a href="https://t.co/1vKZxvn9yz">
                    pic.twitter.com/1vKZxvn9yz
                  </a>
                </p>
                &mdash;
                【公式】PARKLoT🎯インスタントウィンを最速で実施できるキャンペーンツール
                (@PARKLoT1)
                <a href="https://twitter.com/PARKLoT1/status/1584668573523677184?ref_src=twsrc%5Etfw">
                  October 24, 2022
                </a>
              </blockquote>
            </div>
            <div>
              <h2 id="id2">
                インスタントウィンキャンペーンの仕組みは？どうやって実現しているの？
              </h2>
              <p>
                Twitterのインスタントウィンキャンペーンは、Twitter社が提供するAPIを利用し実現しています。Twitter社のAPIを利用することで、Twitterの様々な機能やデータを自社システムの一部として活用できるようになるのです。
              </p>
              <p>
                下記では、Twitter
                インスタントウィンキャンペーンに関係のある機能を抜粋してご紹介しています。これらのTwitter社の機能をAPIを通して利用することで、インスタントウィンキャンペーンの実施が可能になります。
              </p>
              <ol className="blog__border-list">
                <li>ツイートを投稿する</li>
                <li>
                  指定したユーザーが自分のアカウントをフォローしているかチェックする
                </li>
                <li>
                  指定したツイートに「いいね」したユーザーの一覧を取得する
                </li>
                <li>
                  指定したツイートを「リツイート」したユーザーの一覧を取得する
                </li>
                <li>
                  指定したツイートを引用リツイートしたユーザーの一覧と、投稿されたテキストを取得する
                </li>
                <li>メディア(画像や動画)をアップロードする</li>
                <li>指定したユーザーにリプライを送信する</li>
                <li>指定したユーザーにDMを送信する</li>
              </ol>
            </div>
            <div>
              <h2 id="id3">
                インスタントウィンキャンペーンと手動のキャンペーンの違いは？
              </h2>
              <p>
                ここまで読んだ方は、「それで？インスタントウィンキャンペーンと手動のキャンペーンは、具体的にどこが違うの？」と思ったのではないでしょうか。
                <strong>
                  この２つの大きな違いは、「工数削減」と「キャンペーンの効果」です。
                </strong>
              </p>
              <p>2つのキャンペーンの比較を下記にまとめてみました。</p>
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th className="blog__text-center">
                      インスタントウィンキャンペーン
                    </th>
                    <th className="blog__text-center">手動のキャンペーン</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>キャンペーンの始め方</td>
                    <td className="blog__text-center">
                      自動でツイートが投稿される
                    </td>
                    <td className="blog__text-center">
                      手動でツイートを投稿する
                    </td>
                  </tr>
                  <tr>
                    <td>当選者の選出</td>
                    <td className="blog__text-center">
                      景品の個数・当選数を
                      <br />
                      もとに自動で抽選する
                    </td>
                    <td className="blog__text-center">
                      キャンペーン終了後に手動で選ぶ
                    </td>
                  </tr>
                  <tr>
                    <td>当選者のフィルター</td>
                    <td className="blog__text-center">
                      PARKLoTの場合は可能
                      <br />
                      （フォロワー数など）
                    </td>
                    <td className="blog__text-center">
                      可能
                      <br />
                      （手動で選ぶため）
                    </td>
                  </tr>
                  <tr>
                    <td>参加者一覧の抽出</td>
                    <td className="blog__text-center">
                      自動で収集・CSV
                      <br />
                      ダウンロードができる
                    </td>
                    <td className="blog__text-center">不可</td>
                  </tr>
                  <tr>
                    <td>当選者への連絡</td>
                    <td className="blog__text-center">
                      DMまたはリプライで
                      <br />
                      結果を自動通知する
                    </td>
                    <td className="blog__text-center">
                      キャンペーン終了後、当選者選出後に
                      <br />
                      担当者が手動で連絡する
                    </td>
                  </tr>
                  <tr>
                    <td>発送先住所の収集</td>
                    <td className="blog__text-center">PARKLoTの場合は可能</td>
                    <td className="blog__text-center">
                      キャンペーン終了後、当選者に
                      <br />
                      連絡して確認する
                    </td>
                  </tr>
                  <tr>
                    <td>参加者のモチベーション</td>
                    <td className="blog__text-center">
                      高い
                      <br />
                      （すぐに結果がわかるため）
                    </td>
                    <td className="blog__text-center">
                      場合によっては高い
                      <br />
                      （キャンペーン・景品設計に左右される）
                    </td>
                  </tr>
                  <tr>
                    <td>分析（参加者などの分析）</td>
                    <td className="blog__text-center">
                      可能
                      <br />
                      ・参加者の属性、参加時間などの
                      <br />
                      分析が可能
                    </td>
                    <td className="blog__text-center">
                      難しい
                      <br />
                      ・不可能ではないが、Twitter画面から
                      <br />
                      一つ一つ見ていく必要が
                      <br />
                      あるため、工数がかかる
                    </td>
                  </tr>
                  <tr>
                    <td>その他</td>
                    <td className="blog__text-center">
                      「ゲームに勝ったら当選！」
                      <br />
                      「アンケートに答えたら当選！」
                      <br />
                      などのゲーム性を加えた
                      <br />
                      キャンペーンの実施ができる
                    </td>
                    <td className="blog__text-center"> </td>
                  </tr>
                  <tr>
                    <td>コンサルティング</td>
                    <td className="blog__text-center">
                      PARKLoTの場合はあり
                      <br />
                      （バナー作成、景品設計など、
                      <br />
                      効果の出やすいキャンペーンの
                      <br />
                      コンサルティングを行う）
                    </td>
                    <td className="blog__text-center">なし</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h2 id="id4">
                インスタントウィンキャンペーンのメリット・デメリット
              </h2>
              <p>
                インスタントウィンキャンペーンのメリット・デメリットを下記にまとめました。
              </p>
            </div>
            <div>
              <h2 id="id5">メリット</h2>
              <h3 id="id6">工数・人件費の削減になる</h3>
              <p>
                キャンペーンは実施したら終わりではなく、キャンペーン終了後に「当選者の選出」、「景品発送先住所の確認」、「当選者への連絡」など様々な作業を行う必要があります。これらを手動で行うと、４〜６時間はかかってしまいます。インスタントウィンキャンペーンであれば、上記の作業を完全に自動化することができるため、20分程度の時間で作業が完了します。結果的に、工数や人件費を削減することができます。
              </p>
              <p>
                <a
                  href="/case-study/twitter/instant-win-follow-and-retweet"
                  target="_blank"
                  rel="noreferrer"
                >
                  事例紹介 フォロー＆リツイート（インスタントウィン）
                </a>
                では、インスタントウィンキャンペーンの具体的な流れと作業について解説しています。ぜひご覧ください！
              </p>
            </div>
            <div>
              <h3 id="id7">当選者選出の手間と時間がかからない</h3>
              <p>
                当選者の選出は、参加者の一覧から一人一人プロフィールを見に行き、当選に値するかを判断する必要があるため、思ったよりも手間ひまがかかります…。当選者が数人の場合は問題ありませんが、当選者が数十人の場合は、当選者の選出作業だけで何時間も取られてしまうことも。
              </p>
              <p>
                インスタントウィンツールを導入すれば、当選数と当選確率をもとに当選者を自動で選出してくれるため、時間の節約ができるのです。
              </p>
            </div>
            <div>
              <h3 id="id8">手軽なので何度も実施できる</h3>
              <p>
                インスタントウィンキャンペーンは、時間のかかる作業を自動化することができるため、工数がかかりません。そのため、何度も実施することができるというメリットがあります。
              </p>
              <p>
                一度のキャンペーンで結果が出なくても、何度も手軽に実施することでフォロワー獲得や認知度の向上を見込むことができるのです。
              </p>
            </div>
            <div>
              <h3 id="id9">短期間で多くのフォロワー獲得が期待できる</h3>
              <p>
                インスタントウィンキャンペーンの特徴は「すぐに結果がわかる」というワクワク感が感じられることにあるため、手動のキャンペーンよりも参加者が多くなる傾向にあります。さらに、リツイートによる拡散によってさらに多くの人の目に留まるため、結果的に短期間で多くのフォロワーを獲得することができるのです。
              </p>
              <p>
                これによって、新商品や新サービスの認知向上につながるだけでなく、これまでリーチできなかった潜在顧客へアプローチができるようになります。
              </p>
            </div>
            <div>
              <h2 id="id10">デメリット</h2>
            </div>
            <div>
              <h3 id="id11">費用がかかる</h3>
              <p>
                インスタントウィンキャンペーンの最大のデメリットは費用がかかってしまうことでしょう。サービスによりますが、インスタントウィンのキャンペーン実施費用は数万円〜100万円と高値の場合が多く、キャンペーン予算が多くない企業にとっては手が出しにくい金額となっています。
              </p>
              <p>
                PARKLoTの場合、定額制（月5万円〜）で何度でもキャンペーンを実施できるプランをご用意しています。これらのプランを検討し、安くインスタントウィンキャンペーンを実施することを検討しましょう。
              </p>
              <p>
                それでも予算を出すことが難しい場合は、多少の工数がかかることを考慮した上で手動でキャンペーンを実施することをお勧めします。
              </p>
              <p>
                PARKLoTでは、当選者へのDM送信代行も行なっています。詳しくは、
                <a
                  href="/case-study/twitter/twitter-non-instant-win"
                  target="_blank"
                  rel="noreferrer"
                >
                  事例紹介 フォロー＆リツイート（後日抽選）
                </a>
                をご覧ください。
              </p>
            </div>
            <div>
              <h3 id="id12">
                インスタントウインキャンペーンをやるべき？それとも手動でやるべき？
              </h3>
              <p>
                インスタントウィンキャンペーンは手動のキャンペーンよりもメリットが多いため、「とりあえずキャンペーンを実施して様子を見てみたい」、「ツールを導入する費用がない」などの特別な事情がない限りは、ツールを導入してインスタントウィンキャンペーンを実施することをお勧めします。
              </p>
              <p>
                <a
                  href="/blog/comparison-no-tool"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitterキャンペーンで本当にツールは必要なのか？
                  お金をかけずに手動でできる方法は？
                </a>
                でツールを導入すべきかに関して詳しく説明しているので、ぜひご覧ください！
              </p>
              <p>
                PARKLoTでは、月額5万円〜でキャンペーンを何度でも実施することができます。話を聞いてみたい、見積もりが欲しい、などあれば下記のボタンからお気軽にご相談ください！
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="momoko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/tips-for-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【SNSキャンペーン】本気で販促につなげるなら！すぐできる10のポイント
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default ComparisonOfInstantWinAndLaterLottery
